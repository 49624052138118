table {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
}

table thead tr {
  background: #293462;
  color: #fff;
  text-align: left;
}

table th,
table td {
  padding: 26px 0 26px 20px;
}

table tbody tr {
  border-bottom: 1px solid #dddddd;
}

table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

table tbody tr:last-of-type {
  border-bottom: 2px solid #293462;
}
